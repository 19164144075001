
    import { defineComponent, ref, reactive, toRaw, toRefs, unref, computed } from 'vue';
    import {SetupContext} from "@vue/runtime-core";
    export default defineComponent({
        name: 'el-search',
        props: {
            search: {
                type: Array,
                default: []
            }
        },
        setup(props: any, ctx: SetupContext) {
            
            const { search } = toRefs(props);
    
            search.value.filter((j: any) => typeof j.options === 'function').forEach((k: any) => k.options().then((body: any) => k.options = body))
            
            const onSearch = () => {
                let params: Array<any> = [];
                search.value.forEach((s: any) => {
                    if (s.value !== undefined && s.value !== '') {
                        params.push({[s.key]: s.value})
                    }
                })
                ctx.emit('on-search', params);
            }
            
            return {
                onSearch
            }
        }
    })
