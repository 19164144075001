<template>
    <div class="search-group" v-if="search.length > 0">
        <template v-for="item in search">
            <div v-if="item.tag === 'input'">
                {{item.label}}:
                <a-input
                    allowClear
                    v-model:value="item.value"
                    :style="{width: item.width || '160px'}"></a-input>
            </div>
            <div v-if="item.tag === 'select'">
                {{item.label}}:
                <a-select
                    allowClear
                    v-model:value="item.value"
                    :style="{width: item.width || '160px'}">
                    <a-select-option v-for="it in item.options" :key="it.key">{{it.value}}</a-select-option>
                </a-select>
            </div>
        </template>
        <div>
            <a-button type="primary" @click="onSearch">搜索</a-button>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, ref, reactive, toRaw, toRefs, unref, computed } from 'vue';
    import {SetupContext} from "@vue/runtime-core";
    export default defineComponent({
        name: 'el-search',
        props: {
            search: {
                type: Array,
                default: []
            }
        },
        setup(props: any, ctx: SetupContext) {
            
            const { search } = toRefs(props);
    
            search.value.filter((j: any) => typeof j.options === 'function').forEach((k: any) => k.options().then((body: any) => k.options = body))
            
            const onSearch = () => {
                let params: Array<any> = [];
                search.value.forEach((s: any) => {
                    if (s.value !== undefined && s.value !== '') {
                        params.push({[s.key]: s.value})
                    }
                })
                ctx.emit('on-search', params);
            }
            
            return {
                onSearch
            }
        }
    })
</script>

<style scoped lang="less">

    .search-group {
        display: flex;
        flex-wrap: wrap;
        >div {
            margin: 10px;
        }
    }
    
</style>